import React from 'react';
import { Box, Typography } from '@achieve/sunbeam';
import { CheckCircle, X } from 'react-feather';
import { useTranslation } from 'lib/hooks';

import FullImage from './assets/full-image.png';

export function AppPromoBody({
  renderBullets = true,
  renderImage = true,
  dismissPromo = null
}) {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      data-testid="native-app-cta-body"
    >
      <Box display="flex" gap="20px" justifyContent="flex-end">
        <Typography component="h2" variant="ascendHeadingSm">
          {t('appPromo.stayInTheKnowOnTheGo')}
        </Typography>
        {dismissPromo && (
          <X
            data-testid="native-app-cta-dismiss-promo"
            size="24px"
            onClick={dismissPromo}
          />
        )}
      </Box>
      {renderImage && (
        <Box>
          <img
            width="100%"
            src={FullImage}
            alt="Rendering of the FDR App on an iPhone"
          />
        </Box>
      )}
      {renderBullets && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" gap={'8px'}>
            <Box display="flex" alignItems="center">
              <CheckCircle size={24} />
            </Box>
            <Typography component="p" variant="ascendBodySm">
              {t('appPromo.instantlyApproveSettlements')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={'8px'}>
            <Box display="flex" alignItems="center">
              <CheckCircle size={24} />
            </Box>
            <Typography component="p" variant="ascendBodySm">
              {t('appPromo.viewRealTimeProgramUpdates')}
            </Typography>
          </Box>
        </Box>
      )}
      <Typography component="p" variant="ascendBodySm">
        {t('appPromo.keepTrackOfEveryDebt')}
      </Typography>
    </Box>
  );
}
