import React from 'react';
import { Box, Button, Dialog, Typography } from '@achieve/sunbeam';

import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation, useAppPromo } from 'lib/hooks';
import { AppPromoBody } from './PromoBody';

const APP_DOWNLOAD_URL = 'https://freedomdebtrelief.onelink.me/YHp1/nsar320u';

const handleAppCTAInteraction = actionType => {
  analyticsTrackEvent(
    {
      category: 'native_app_cta',
      action: actionType,
      label: 'native_app_cta_modal_interaction'
    },
    // Uppercasing first letter for formatting
    `Native App Download CTA ${actionType.charAt(0).toUpperCase() +
      actionType.slice(1)}`
  );
};

export const Modal = () => {
  const { showPromo, dismissPromo } = useAppPromo('modal');
  const { t } = useTranslation();

  return (
    <Box data-testid="native-app-cta-modal">
      {/*** Adding z-index to prevent conflicts with Product Tour. This should appear above everything. */}
      <Dialog open={showPromo} sx={{ zIndex: 111111 }}>
        <Box display="flex" flexDirection="column" gap={3} padding="24px">
          <AppPromoBody />
          <Button
            data-testid="native-app-cta-download-button"
            variant="contained"
            href={APP_DOWNLOAD_URL}
            onClick={() => {
              handleAppCTAInteraction('download');
              dismissPromo();
            }}
          >
            <Typography variant="ascendLabelMd">
              {t('appPromo.getTheApp')}
            </Typography>
          </Button>
          <Button
            data-testid="native-app-cta-dismiss-button"
            variant="text"
            onClick={() => {
              handleAppCTAInteraction('dismiss');
              dismissPromo();
            }}
          >
            <Typography variant="ascendLabelMd">
              {t('appPromo.remindMeLater')}
            </Typography>
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Modal;
